<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { collapsed } from './state'

export default {
    props: {
        to: { type: String, required: true },
        icon: { type: String, required: true }
    },
    setup(props) {
        const route = useRoute()
        const isActive = computed(() => route.path === props.to)
        return { isActive, collapsed }
    }
}
</script>

<template>
    <router-link :to="to" class="link" :class="{ active: isActive }">
    <i class="icon" :class="icon" />
    <transition name="fade">
        <span v-if="!collapsed">
            <slot />
        </span>
    </transition>
    </router-link>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.link {
    display: flex;
    align-items: center;

    cursor: pointer;
    position: relative;
    font-weight: 400;
    user-select: none;

    margin: 0.3em 0;
    padding: 1em;
    border-radius: 0.7em;
    height: 1.5em;

    color: rgb(47, 47, 47);
    text-decoration: none;
}

.link:hover {
    background-color: var(--sidebar-item-hover);
}

.link.active {
    background-color: var(--sidebar-item-active);
    color: white;
}

.link .icon {
    flex-shrink: 0;
    width: 25px;
    margin-right: 10px;
}
.icon{
    margin-top: 5px;
    font-size : 18px;
    color: #1F1F22;
}
.active i{
    color: white;
}

</style>