export default [
    "#3772FF", 
    "#FF7171", 
    "#EA8EEC", 
    "#9160D1", 
    "#4A70CB", 
    "#FFA500", 
    "#FFA07A", 
    "#FFBF00", 
    "#80B600", 
    "#3CB371", 
    "#6495ED",
];